import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Container, Table, Card, Fade } from 'react-bootstrap';
import {
   Button,
   Modal,
   Pagination,
   PageErrorLoad,
   PageListingNotFound,
   convertStringToDate,
   SelectAutocomplete,
   Radio,
} from '@devesharp/react/web';
import { Form } from '@unform/web';
import DateFNS from 'date-fns';
import QueryString from 'querystring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Style, { ProgressColor } from './EstablishmentCreatePage.style';
import { PaymentStatus, Header, LoadingPage, LinkPaymentStatus } from '~/components';
import { CNPJAndCPFMask, formaHour, formatDate, formatPrice } from '~/services';
import history from '~/config/history';
import { useEstablishmentListingPage } from '~/pages/EstablishmentListing/EstablishmentListingPage.controller';
import { useEstablishmentCreatePage } from '~/pages/EstablishmentCreate/EstablishmentCreatePage.controller';
import { GroupsSearchResolve } from '~/api';
import { Select } from '~/components/Customs/Select';
import { Input } from '~/components/Customs/Input';
import {SelectAutoComplete} from "~/components/Customs/SelectAutoComplete";

export const EstablishmentCreatePage: React.FunctionComponent<any> = () => {
   const ctrl = useEstablishmentCreatePage();

   return (
      <Style>
         <div className="title-page">
            <div className="container">
               <div className="p-3">
                  <div className="row">
                     <div className="col">
                        <h3 className="m-0 mb-1">
                           <h3 className="m-0 mb-1">
                              {ctrl.editing ? `Editar estabelecimento` : 'Novo estabelecimento'}
                           </h3>
                        </h3>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         {(ctrl.saving || ctrl.starting) && <LoadingPage />}

         <div className="container-form m-auto">
            <Form ref={ctrl.formRef} onSubmit={ctrl.onSubmit} initialData={ctrl.initialData}>
               <Card className="p-n">
                  <div className="form-padding">
                     <div className="form-title">Tipo do estabelecimento</div>
                     <br />
                     <Radio
                        name="type"
                        options={[
                           { label: 'Pessoa Física', id: 'individual' },
                           { label: 'Pessoa Jurídica', id: 'business' },
                        ]}
                        onChange={ctrl.changePersonType}
                     />
                  </div>
                  <hr />
                  {ctrl.personType == 'individual' && <PessoaFisica ctrl={ctrl} />}
                  {ctrl.personType == 'business' && <PessoaJuridica ctrl={ctrl} />}

                  <hr />
                  <div className="form-padding ">
                     <div className="form-title">Conta bancária</div>

                     <br />

                     <div className="pb-3">A conta bancária precisa ser do mesmo titular do estabelecimento.</div>

                     <div className="row field-box">
                        <div className="col-md-12">
                           <Select name="bank_type" title="Tipo de Conta" options={ctrl.formInfoData.accountType} />
                        </div>
                     </div>

                     <div className="row field-box">
                        <div className="col-md-12">
                           <SelectAutoComplete name="bank_code" title="Banco" options={ctrl.formInfoData.bankCodes} />
                        </div>
                     </div>

                     <div className="row field-box">
                        <div className="col-md-4">
                           <Input name="bank_routing_number" title="Agência da Conta" />
                        </div>

                        <div className="col-md-3">
                           <Input name="bank_account_number" title="Conta Bancária" />
                        </div>
                     </div>
                  </div>

                  <div className="form-padding ">
                     <div className="form-title">Acesso ao Painel</div>

                     <div className="row field-box">
                        <div className="col-md-6">
                           <Input name="user_login" title="Login" />
                        </div>

                        <div className="col-md-6">
                           <Input name="user_password" title="Senha de acesso" />
                        </div>
                     </div>
                  </div>
               </Card>
               <div className="text-right pt-2">
                  <Button size="lg" type="button" onClick={ctrl.submit}>
                     <b>{ctrl.editing ? 'Editar estabelecimento' : 'Criar novo estabelecimento'}</b>
                  </Button>
               </div>
               <br />
               <br />
            </Form>
         </div>
      </Style>
   );
};

function PessoaFisica({ ctrl }: any) {
   return (
      <>
         <div className="form-padding ">
            <div className="form-title">Dados do contato</div>

            <br />

            <>
               <div className="row field-box">
                  <div className="col-md-6">
                     <Input name="first_name" title="Nome do vendedor" />
                  </div>

                  <div className="col-md-6">
                     <Input name="last_name" title="Sobrenome do vendedor" />
                  </div>
               </div>
            </>

            <div className="row field-box">
               <div className="col-md-6">
                  <Input name="email" title="Email do vendedor" />
               </div>

               <div className="col-md-6">
                  <Input name="phone_number" title="Telefone do vendedor" phone />
               </div>
            </div>

            <div className="row field-box">
               <div className="col-md-6">
                  <Input name="taxpayer_id" title="CPF do vendedor" CPF />
               </div>

               <div className="col-md-6">
                  <Input name="birthdate" title="Data de nascimento do vendedor" date />
               </div>
            </div>

            <div className="row field-box">
               <div className="col-md-6">
                  <Input name="statement_descriptor" title="Nome na fatura do associado" />
               </div>

               <div className="col-md-6">
                  <Input name="revenue" title="Renda" Number />
               </div>
            </div>
 
            <div className="row field-box">
               <div className="col-md-9">
                  <Select name="mcc" title="MCC" options={ctrl.formInfoData.MCC} />
               </div>
            </div>
         </div>
         <hr />
         <div className="form-padding ">
            <div className="form-title">Endereço do vendedor</div>

            <br />
            <div className="row field-box">
               <div className="col-md-9">
                  <Input name="address_line1" title="Endereço" />
               </div>

               <div className="col-md-3">
                  <Input name="address_line2" title="Número" number />
               </div>
            </div>
            <div className="row field-box">
               <div className="col-md-12">
                  <Input name="address_line3" title="Complemento" />
               </div>
            </div>

            <div className="row field-box">
               <div className="col-md-4">
                  <Input name="address_neighborhood" title="Bairro" />
               </div>

               <div className="col-md-5">
                  <Input name="address_city" title="Cidade" />
               </div>

               <div className="col-md-3">
                  <Select name="address_state" title="Estado" options={ctrl.formInfoData.states} />
               </div>
            </div>
            <div className="row field-box">
               <div className="col-md-4">
                  <Input
                     name="address_postal_code"
                     title="CEP"
                     CEP
                     onInput={ctrl.onChangeCep}
                     loading={ctrl.isLoadingCep}
                  />
               </div>
            </div>
         </div>
      </>
   );
}

function PessoaJuridica({ ctrl }: any) {
   
   return (
      <>
         <div className="form-padding ">
            <div className="form-title">Dados do proprietário</div>

            <br />

            <>
               <div className="row field-box">
                  <div className="col-md-6">
                     <Input name="owner_first_name" title="Nome do proprietário" />
                  </div>

                  <div className="col-md-6">
                     <Input name="owner_last_name" title="Sobrenome do proprietário" />
                  </div>
               </div>
            </>

            <div className="row field-box">
               <div className="col-md-6">
                  <Input name="owner_email" title="Email do proprietário" />
               </div>

               <div className="col-md-6">
                  <Input name="owner_phone_number" title="Telefone do proprietário" phone />
               </div>
            </div>

            <div className="row field-box">
               <div className="col-md-6">
                  <Input name="owner_taxpayer_id" title="CPF do proprietário" CPF />
               </div>

               <div className="col-md-6">
                  <Input name="owner_birthdate" title="Data de nascimento do proprietário" date />
               </div>
            </div>
         </div>
         <hr />
         <div className="form-padding ">
            <div className="form-title">Dados da empresa</div>
            <br/>
            <div className="row field-box">
               <div className="col-md-6">
                  <Input name="business_name" title="Razão Social"/>
               </div>
               <div className="col-md-6">
                  <Input name="statement_descriptor" title="Nome fantasia"/>
               </div>
               {/* <div className="col-md-6"> */}
               {/*   <Input name="description" title="Descrição da empresa" /> */}
               {/* </div> */}
            </div>
            <div className="row field-box">
               <div className="col-md-6">
                  <Input name="business_phone" title="Telefone da empresa" phone/>
               </div>
               <div className="col-md-6">
                  <Input name="business_email" title="Email da empresa"/>
               </div>
            </div>
            {/* <div className="row field-box"> */}
            {/*   <div className="col-md-12"> */}
            {/*      <Input name="business_description" title="Descrição da empresa" /> */}
            {/*   </div> */}
            {/* </div> */}
            <div className="row field-box">
               <div className="col-md-6">
                  <Input name="ein" title="CNPJ da empresa" CNPJ/>
               </div>
               <div className="col-md-6">
                  <Input name="revenue" title="Faturamento da empresa" prefix="R$" number/>
               </div>
            </div>
            <div className="row field-box">
               <div className="col-md-6">
                  <Input name="business_opening_date" title="Data de abertura da empresa" date/>
               </div>
            </div>
            <div className="row field-box">
               <div className="col-md-12">
                  <SelectAutoComplete name="mcc" title="Categoria do negócio (MCC)" options={ctrl.formInfoData.MCC}/>
               </div>
            </div>
         </div>
         <hr/>
         <div className="form-padding ">
            <div className="form-title">Localização da empresa</div>

            <br />

            <div className="row field-box">
               <div className="col-md-9">
                  <Input name="business_address_line1" title="Endereço" />
               </div>

               <div className="col-md-3">
                  <Input name="business_address_line2" title="Número" number />
               </div>
            </div>
            <div className="row field-box">
               <div className="col-md-12">
                  <Input name="business_address_line3" title="Complemento" />
               </div>
            </div>

            <div className="row field-box">
               <div className="col-md-4">
                  <Input name="business_address_neighborhood" title="Bairro" />
               </div>

               <div className="col-md-5">
                  <Input name="business_address_city" title="Cidade" />
               </div>

               <div className="col-md-3">
                  <Select name="business_address_state" title="Estado" options={ctrl.formInfoData.states} />
               </div>
            </div>
            <div className="row field-box">
               <div className="col-md-4">
                  <Input
                     name="business_address_postal_code"
                     title="CEP"
                     CEP
                     onInput={ctrl.onChangeCep}
                     loading={ctrl.isLoadingCep}
                  />
               </div>
            </div>
         </div>
         <hr />
         <div className="form-padding ">
            <div className="form-title">Localização do proprietário</div>

            <br />

            <div className="row field-box">
               <div className="col-md-9">
                  <Input name="owner_address_line1" title="Endereço" />
               </div>

               <div className="col-md-3">
                  <Input name="owner_address_line2" title="Número" number />
               </div>
            </div>
            <div className="row field-box">
               <div className="col-md-12">
                  <Input name="owner_address_line3" title="Complemento" />
               </div>
            </div>

            <div className="row field-box">
               <div className="col-md-4">
                  <Input name="owner_address_neighborhood" title="Bairro" />
               </div>

               <div className="col-md-5">
                  <Input name="owner_address_city" title="Cidade" />
               </div>

               <div className="col-md-3">
                  <Select name="owner_address_state" title="Estado" options={ctrl.formInfoData.states} />
               </div>
            </div>
            <div className="row field-box">
               <div className="col-md-4">
                  <Input
                     name="owner_address_postal_code"
                     title="CEP"
                     CEP
                     onInput={ctrl.onChangeCep}
                     loading={ctrl.isLoadingCep}
                  />
               </div>
            </div>
         </div>
      </>
   );
}
