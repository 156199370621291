import styled from 'styled-components';

export const Container = styled.div<any>`
   select {
      padding-left: 10px !important;
   }
   .input-title {
      ${(p) => p.error && `color: red !important`};
      ${(p) => p.disabled && `color: grey !important`};
      font-weight: 500 !important;
      text-transform: none !important;
      font-size: 14px !important;
      padding-bottom: 3px;
   }
   .input-title.focus {
      color: ${(p) => p.theme.primary} !important;
   }
   .input-border.has-error {
      ${(p) => p.error && `border-bottom: 1px solid ${p.theme.danger} !important`};
   }
   select.has-error {
      ${(p) => p.error && `border-bottom: 1px solid ${p.theme.danger} !important`};
   }
   .error {
      color: red !important;
   }
`;
