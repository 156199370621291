import Select from 'react-select';
import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { ISelectAutoCompleteProps } from './SelectAutoComplete.interface';
import * as S from './SelectAutoComplete.style';
import { UnformInput } from '../unform-input';

export const SelectAutoComplete = React.forwardRef<InputHTMLAttributes<any>, ISelectAutoCompleteProps>(
   function SelectAutoComplete(props, inputRef: any) {
      const { onError, disabled, placeholder, title, options: _options, onChange, value } = props;
      const options = _options.map(({ value, name }) => ({ value: value, label: name }));
      const [error, setError] = useState('');
      const [selected, setSelected] = useState(options.find((d: any) => d.value == ''));

      function onChangeSelect(data: any) {
         if (!data?.value) {
            return setSelected(options.find((d: any) => d.value == data));
         }

         setSelected(data);
         onChange?.(data.value);
      }

      useEffect(() => {
         if (value) setSelected(options.find((d: any) => d.value == value));
      }, [value]);

      const theme = useTheme();

      const customStyles = {
         control: (provided: any, state: any) => ({
            ...provided,
            border: 'none', // Remove todas as bordas
            borderBottom: state.isFocused ? '1px solid rgba(0,0,0,0.25)' : '1px solid rgba(0,0,0,0.25)', // Borda apenas na parte inferior
            borderRadius: '0px', // Remove bordas arredondadas
            boxShadow: state.isFocused ? '1px solid rgba(0,0,0,0.25)' : 'none', // Adiciona sombra azul ao focar
            // padding: '0px', // 🔥 Remove padding interno
         }),
         dropdownIndicator: (provided: any) => ({
            ...provided,
            color: 'black', // Cor do chevron (seta para baixo)
         }),
         clearIndicator: (provided: any) => ({
            ...provided,
            color: 'black', // Cor do X (ícone de limpar)
         }),
         menu: (provided: any) => ({
            ...provided,
            backgroundColor: 'white', // Fundo branco ao abrir as opções
         }),
         option: (provided: any, state: any) => ({
            ...provided,
            fontWeight: state.isSelected ? '600' : 'normal', // Aumenta o peso do texto do item selecionado
            backgroundColor: state.isSelected ? '#ddd' : state.isFocused ? '#f0f0f0' : 'white', // Mantém fundo branco, muda o selecionado
            color: 'black', // Cor do texto normal
         }),
         singleValue: (provided: any) => ({
            ...provided,
            fontWeight: 600,
            color: '#444',
         }),
      };

      return (
         <S.Container error={error} disabled={disabled}>
            {!!props.name && (
               <UnformInput
                  name={props.name}
                  getValue={() => selected?.value}
                  onChange={onChangeSelect}
                  onError={(error: any) => setError(error)}
               />
            )}
            <div className="input-title">{title}</div>
            <Select
               value={selected}
               isDisabled={disabled}
               // isLoading={isLoading}
               isClearable
               // isSearchable={isSearchable}
               options={options}
               onChange={(d: any) => onChangeSelect(d)}
               placeholder="Selecione uma opção..."
               styles={customStyles}
            />
            {!!error && (
               <div style={{ paddingLeft: '6px', paddingTop: '3px' }}>
                  <span className="error">{error}</span>
               </div>
            )}
         </S.Container>
      );
   },
);
